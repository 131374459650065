<template>
    <div class="flex flex-col">
        <ul
            v-if="responseErrors.length"
            class="mb-4"
        >
            <li
                class="text-roman text-li text-center"
                v-for="(error, id) in responseErrors"
                :key="error.code + id"
                v-html="error.title"
            >
            </li>
        </ul>

        <form
            class="flex flex-col w-full items-center"
            method="post"
            @submit.prevent="submit"
            novalidate
        >
            <BasicInput
                v-if="showEmail"
                v-model="formData.email"
                ref="email"
                :is-required="true"
                :is-loading="false"
                input-type="email"
                input-id="login-email"
                input-label="Email"
                :input-classes="formErrors.email ? '!border-roman' : ''"
                :tooltip-error-text="formErrors.email"
                @keypress="removeError('email')"
                @change="removeError('email')"
                autocomplete="email"
                class="h-full mt-4 first:mt-0 input-pass-login w-full max-w-[15.375rem]"
            />
            <BasicInput
                v-else
                v-model="formData.phone"
                ref="phone"
                :is-required="true"
                :is-loading="false"
                input-type="tel"
                input-id="login-phone"
                input-label="Телефон"
                :input-classes="formErrors.phone ?  '!border-roman' : ''"
                :tooltip-error-text="formErrors.phone"
                v-maska="'+7 ### ###-##-##'"
                @keypress="removeError('phone')"
                @change="removeError('phone')"
                autocomplete="tel"
                class="h-full mt-4 first:mt-0 input-pass-login w-full max-w-[15.375rem]"
            />
            <BasicInput
                v-model="formData.password"
                ref="password"
                :is-required="true"
                :is-loading="false"
                input-type="password"
                input-id="login-password"
                input-label="Пароль"
                :input-classes="formErrors.password ? '!border-roman' : ''"
                :tooltip-error-text="formErrors.password"
                @keypress="removeError('password')"
                @change="removeError('password')"
                class="h-full mt-4 first:mt-0 input-pass-login w-full max-w-[15.375rem]"
            />
            <button
                class="button !text-white bg-black max-w-[15.375rem] mt-4 w-full !px-0 code-request hover:!bg-[rgba(0,0,0,.30)] disabled:pointer-events-none disabled:bg-black/30"
                type="submit"
                :disabled="formData.password.length < 2 || formData.email.length < 2 || !isAcceptTheTerms"
            >
                Войти в аккаунт
            </button>
        </form>
        <a href="/login/" class="text-center text-black text-body mt-10 fade-hover-effect">Вход по СМС</a>
        <div class="flex gap-2 items-start w-full mx-auto color-shuttle-gray mt-10 px-8 xsm:px-0">
            <div
                class="relative min-w-8 h-4 rounded-lg cursor-pointer"
                :class="{
                    [isAcceptTheTerms
                        ? 'border-dark-green'
                        : 'pink-antique']: true,
                    [isAcceptTheTerms
                        ? 'bg-dark-green'
                        : 'bg-pink-antique']: true,
                }"
                @click="toggleLoginMethod()"
            >
                <div class="absolute w-4 h-4 bg-white rounded-full left-0 top-0 transition-all border-2 border-solid"
                    :class="{
                        'left-4': isAcceptTheTerms,
                        [isAcceptTheTerms
                            ? 'border-dark-green'
                            : 'pink-antique']: true,
                    }"></div>
            </div>
            <div class="text-caption text-shuttle-gray">
                Cоглашаюсь с условиями
                <a class="text-caption text-black no-underline fade-hover-effect hover:text-shuttle-gray" href="/license/" target="_blank">
                    Политики конфиденциальности
                </a>
                и даю
                <a class="text-caption text-black no-underline fade-hover-effect hover:text-shuttle-gray" href="/personalnye-dannye/" target="_blank">
                    Согласие на обработку персональных данных
                </a>
                и получение сообщений.
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ref,
    reactive,
    ComponentPublicInstance,
}                           from 'vue'
import BasicInput           from '~vue/misc/BasicInput.vue'
import { fetchRequest }     from "~/helpers/fetch"
import {
    JsonApiSingle,
    JsonApiError
}                           from '~/interfaces/json-api'
import { AuthLogin }        from '~/interfaces/auth/login'

type BasicInputInstance = ComponentPublicInstance<typeof BasicInput> | null
type errorsKey = keyof typeof formErrors

defineProps({
    inDevelopment: {
        required: false,
        type: Boolean,
        default: false
    }
})

let showEmail = ref(true)
const isAcceptTheTerms = ref<boolean>(true)

let formData = reactive({
    phone: "",
    email: "",
    password: "",
})

let responseErrors = ref<JsonApiError[]>([])

let formErrors = reactive({
    phone: "",
    email: "",
    password: "",
})

const phone = ref<BasicInputInstance>(null)
const email = ref<BasicInputInstance>(null)
const password = ref<BasicInputInstance>(null)

const validateForm = () => {
    let eagerToFocusElement: HTMLInputElement | null = null
    let status = true

    if (status && showEmail.value && (!formData.email || !validateRegex('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$', formData.email))) {
        formErrors.email = 'Укажите корректный адрес электронной почты'

        eagerToFocusElement = email.value?.$refs.self as HTMLInputElement

        status = false
    }

    if (status && !showEmail.value && (!formData.phone || !validateRegex('^\\+7\\s[0-9]{3}\\s[0-9]{3}-[0-9]{2}-[0-9]{2}$', formData.phone))) {
        formErrors.phone = 'Укажите номер телефона полностью'

        eagerToFocusElement = phone.value?.$refs.self as HTMLInputElement

        status = false
    }

    if (status && !formData.password) {
        formErrors.password = 'Поле должно быть заполнено'

        eagerToFocusElement = password.value?.$refs.self as HTMLInputElement

        status = false
    }

    if (eagerToFocusElement) {
        eagerToFocusElement.focus()
    }

    return status
}

const validateRegex = (exspresion: string, string: string) => {
    const regex = new RegExp(exspresion)
    return regex.test(string)
}

const toggleLoginMethod = (forcedValue?: boolean) => {
    if (forcedValue) {
        isAcceptTheTerms.value = forcedValue
    } else {
        isAcceptTheTerms.value = !isAcceptTheTerms.value
    }
}

const submit = async () => {
    if (!validateForm() || !isAcceptTheTerms.value) return void 0

    responseErrors.value = []

    try {
        const body = new FormData()

        Object.entries(formData).forEach(([key, value]) => {
            body.append(key, value.toString())
        })

        if (showEmail.value) {
            body.append("submitLogin", "1")
        } else {
            body.append("submitLoginPhone", "1")
        }

        body.append("ajax", "1")

        const response = await fetchRequest("/login/?ajax=1&action=auth", {
            method: "POST",
            body
        })

        parseResponse(response)
    } catch (error) {
        let errorMessage = '500 от сервера'
        
        if (error instanceof Error) errorMessage = error.message
        
        const defaultError: JsonApiError = {
            status: '500',
            code: '500-internal',
            title: 'Упс, что-то пошло не так 😱. Попробуйте повторить попозже111',
            detail: errorMessage
        }

        responseErrors.value.push(defaultError)
    }
}

const parseResponse = (payload: JsonApiSingle) => {
    
    if (payload.errors) {
        responseErrors.value = payload.errors

        return void 0
    }

    const authResult = payload.data as AuthLogin

    if (authResult.attributes.shouldRedirect) {
        window.location.href = "/"
    }
}

const removeError = (key: errorsKey) => {
    formErrors[key] = ""
}
</script>
