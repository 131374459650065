import { createApp } from 'vue'
import Maska from 'maska'
import { createBem } from '@vuebits/bem'
import TheHeaderProfileForms    from "~vue/header/profile/TheHeaderProfileForms.vue"

window.addEventListener('DOMContentLoaded', () => {
    const loginPageContainer = document.querySelector('.login-page') as HTMLElement
    const loginForm = document.querySelector('#login-page-form') as HTMLElement

    if (!loginForm || !loginPageContainer) return

    changeLoginHeight(loginPageContainer)

    const formType = loginForm.getAttribute('data-type')

    createApp(TheHeaderProfileForms, {type: formType === 'phone'})
        .use(createBem({
            hyphenate: true
        }))
        .use(Maska)
        .mount('#login-page-form')
})

const changeLoginHeight = (container: HTMLElement) => {
    const header = document.querySelector('#header') as HTMLElement
    const headerBanner = document.querySelector('.header-banner') as HTMLElement
    const footerMobile = document.querySelector('.mobile-navigation') as HTMLElement

    const headerHeight = header ? header.offsetHeight : 0
    const headerBannerHeight = headerBanner ? headerBanner.offsetHeight : 0
    const footerMobileHeight = footerMobile ? footerMobile.offsetHeight : 0

    const totalHeight = headerHeight + headerBannerHeight + footerMobileHeight

    container.style.height = `calc(100vh - ${totalHeight}px)`
}