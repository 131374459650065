<template>
    <HeaderProfileLoginUsingLoginAndPassword
        v-if="!isPhoneAuth"
        class="mt-10"
        @edit-formLogin-with-your-email-and-password="editFormLoginWithYourEmailAndPassword"
    />
    <TheHeaderProfileLogin
        v-else
        class="mt-10"
        :in-development="inDevelopment"
        :is-form-login-with-your-email-and-password="isFormLoginWithYourEmailAndPassword"
        :is-entering-confirmation-code-from-sms="isEnteringConfirmationCodeFromSms"
        :text-button-enter-code="getTextButtonEnterCode()"
        :timer-status="timerStatus"
        @edit-formLogin-with-your-email-and-password="editFormLoginWithYourEmailAndPassword"
        @edit-entering-confirmation-code-from-sms="editEnteringConfirmationCodeFromSMS"
        @edit-timer-status="editTimerStatus"
    />

    <!-- <TheHeaderProfileRegistration
        v-else
    /> -->
</template>

<script setup lang="ts">
import { ref, defineComponent, useAttrs} from "vue"
import TheHeaderProfileLogin from '~vue/header/profile/TheHeaderProfileLogin.vue'
import HeaderProfileLoginUsingLoginAndPassword from '~vue/header/profile/HeaderProfileLoginUsingLoginAndPassword.vue'
import {getCookie} from "~/helpers/cookies"
import SvgIcon from "~vue/misc/SvgIcon.vue"
// import TheHeaderProfileRegistration from '~vue/header/profile/TheHeaderProfileRegistration.vue'

let timerStatus = ref(false)
const inDevelopment = ref(true)
const isFormLoginWithYourEmailAndPassword = ref(false)
let isEnteringConfirmationCodeFromSms = ref(false)
const isPhoneAuth = ref<boolean>(useAttrs().type as boolean)

if (getCookie('timerStart') && getCookie('phoneUser')) {
    let cookieTime = getCookie('timerStart')
    let time = new Date().getTime()
    if (60 > ((time-Number(cookieTime))/1000)) {
        isEnteringConfirmationCodeFromSms = ref(true)
        timerStatus = ref(true)
    }
}

const getTextButtonEnterCode = () => {
    return timerStatus.value ? "Ввести код" : "Получить код"
}
const editFormLoginWithYourEmailAndPassword = (params: boolean) => {
    isFormLoginWithYourEmailAndPassword.value = params
    isEnteringConfirmationCodeFromSms.value = false
}
const editTimerStatus = (params: boolean) => {
    if (params === false) {
        isEnteringConfirmationCodeFromSms.value = false
    }
    timerStatus.value = params
}
const editEnteringConfirmationCodeFromSMS = (params: boolean) => {
    isEnteringConfirmationCodeFromSms.value = params
}

defineComponent({
    SvgIcon
})
</script>

<style lang="scss">
    $className: "dropdown-screen__content-width";

    .#{$className} {
        @apply w-[320px] xsm:w-[429px];

        &--narrow {
            @apply w-[320px]
        }
    }
</style>
